/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from "react"
import { StoreContextProvider } from "./src/context/StoreContext"
import { SearchContextProvider } from "./src/context/SearchContext"

export const wrapRootElement = ({ element }) => (
  <SearchContextProvider>
    <StoreContextProvider>{element}</StoreContextProvider>
  </SearchContextProvider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation == null) {
    // First page load, do nothing
    return
  }

  if (location.pathname === "/sourcing/") {
    const bodyElement = document.getElementById("section")
    if (bodyElement) {
      // Scroll to the specific section smoothly with a 20ms delay
      setTimeout(() => {
        bodyElement.scrollIntoView({ behavior: "smooth" })
      }, 20)
    }
  } else {
    // For other paths, scroll to the top smoothly with a 20ms delay
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" })
    }, 20)
  }
}
