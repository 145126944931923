import React, { useState } from "react"

const defaultContext = {
  searchParam: ''
}

export const SearchContext = React.createContext(defaultContext)

export const SearchContextProvider = ({ children }) => {
  const [searchParam, setSearchParam] = useState()
 
  return (
    <SearchContext.Provider value={{ searchParam, setSearchParam }}>
      {children}
    </SearchContext.Provider>
  )
}