exports.components = {
  "component---src-pages-404-copy-js": () => import("./../../../src/pages/404 copy.js" /* webpackChunkName: "component---src-pages-404-copy-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-e-waste-js": () => import("./../../../src/pages/E-waste.js" /* webpackChunkName: "component---src-pages-e-waste-js" */),
  "component---src-pages-electronics-recycling-milwaukee-js": () => import("./../../../src/pages/electronics-recycling-milwaukee.js" /* webpackChunkName: "component---src-pages-electronics-recycling-milwaukee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-milwaukee-js": () => import("./../../../src/pages/milwaukee.js" /* webpackChunkName: "component---src-pages-milwaukee-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-repairs-js": () => import("./../../../src/pages/repairs.js" /* webpackChunkName: "component---src-pages-repairs-js" */),
  "component---src-pages-sell-allen-bradley-js": () => import("./../../../src/pages/sell-allen-bradley.js" /* webpackChunkName: "component---src-pages-sell-allen-bradley-js" */),
  "component---src-pages-sell-components-js": () => import("./../../../src/pages/Sell-Components.js" /* webpackChunkName: "component---src-pages-sell-components-js" */),
  "component---src-pages-sourcing-js": () => import("./../../../src/pages/sourcing.js" /* webpackChunkName: "component---src-pages-sourcing-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-city-template-js": () => import("./../../../src/templates/city-template.js" /* webpackChunkName: "component---src-templates-city-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-shopify-product-template-js": () => import("./../../../src/templates/shopify-product-template.js" /* webpackChunkName: "component---src-templates-shopify-product-template-js" */)
}

